import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { AppLink, ExternalLink } from 'components/Links';

import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { usePartnerCards } from 'page-data/become-a-partner';
import { Cards } from 'components/Cards';
import { TrustPilot } from 'components/TrustPilot';

import {
  EngageTable,
  GrowTable,
  InnovateTable,
  LaunchTable,
} from 'page-data/api-technology-partners';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const partnersFormLink =
  'https://docs.google.com/forms/d/e/1FAIpQLSckS8pCTJWt_Ox1R_t0EDic_NMTg4Kc2JOPKHs6wBvBlDq_fw/viewform';

export const ApiTechnologyPartners: FC<LocalizedPageProps> = () => {
  const partnerCards = usePartnerCards();
  const {
    translations: { freeUsers },
  } = useContentfulLocale();

  return (
    <Layout>
      <Seo />
      <HeroContainer>
        <Section>
          <h1>Technology partnerships</h1>
          <p>Activate data-intelligence for business growth with Sharesight</p>

          <br />
          <br />

          <ExternalLink asButton to={partnersFormLink} appearance="primary">
            Become a partner
          </ExternalLink>
        </Section>

        <SectionImage
          image={useGatsbyImage({
            name: 'shared-page-assets/laptop-graphic',
            alt: 'Graphic illustration of a laptop',
          })}
          boxShadow={false}
        />
      </HeroContainer>
      <Container manageLayout={false}>
        <h2>Activate growth opportunities</h2>
        <p>
          Connect to the <AppLink to="/api">Sharesight API</AppLink> to activate data-intelligence
          for performance insights and tax-reporting. Leverage a growing network of technology
          partners, and access tools and resources to support your business and drive growth.
        </p>
        <br />
        <h4>Account management</h4>
        <p>Dedicated account manager and quarterly business review</p>
        <br />
        <h4>API integration</h4>
        <p>
          Demo environment for API testing, and priority access to the latest API functionality and
          roadmaps
        </p>
        <br />
        <h4>Product training</h4>
        <p>Dedicated product training and onboarding for your team</p>
        <br />
        <h4>Marketing</h4>
        <p>
          Launch activities, access to marketing hub, opportunities for joint marketing campaigns
          and special offers.
        </p>
        <small>*Available activities dependant on partner tier</small>
      </Container>
      <Container manageLayout={false}>
        <h2>Join the Sharesight Partner program</h2>
        <p>
          Your partnership tier will be determined based on a number of strategic growth factors.
          Get started today to activate your growth opportunities with Sharesight.
        </p>
        <LaunchTable />
        <br />
        <GrowTable />
        <br />
        <EngageTable />
        <br />
        <InnovateTable />
      </Container>

      <Cards
        maxImageWidth={80}
        cards={partnerCards}
        header="Why partner with us?"
        subHeader={`Partners work with Sharesight to extend their product functionality, to leverage our API for performance and tax reporting, and to gain exposure to our fast growing universe of more than ${freeUsers} experienced, self-directed investors.`}
      />
      <TrustPilot />
    </Layout>
  );
};

export default ApiTechnologyPartners;
