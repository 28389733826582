const SVGCheckmark = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9321 2L6.26546 10.6663L1.93213 6.33333"
      stroke="#FC5C03"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGCheckmark;
