import styled from 'styled-components';
import Checkmark from 'images/icons/checkmark';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { FlexContainer } from 'components/FlexContainer';
import type { ReactNode } from 'react';

interface TechnologyPartnersRow {
  id: string;
  cells: ReactNode[];
}

const CheckmarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LABEL_WIDTH = 70;

const CHECKMARK_COLUMN_WIDTH = (100 - LABEL_WIDTH) / 4;

const buildRows = (
  labels: {
    key?: string;
    label: ReactNode;
    checkmarks?: { active?: boolean; strategic?: boolean; preferred?: boolean };
  }[]
): TechnologyPartnersRow[] =>
  labels.map(({ key, label, checkmarks }, i) => {
    // defaults to showing checkmarks for all cells

    let cells = [true, true, true];
    if (checkmarks) {
      cells = [!!checkmarks?.active, !!checkmarks?.strategic, !!checkmarks?.preferred];
    }
    let columnNum = 0;
    const checkmarkCells = cells.map(checkmark => {
      columnNum += 1;
      return checkmark ? (
        <CheckmarkWrapper key={`${key}-c${columnNum}`}>
          <Checkmark />
        </CheckmarkWrapper>
      ) : (
        ''
      );
    });

    return {
      id: `r${i}`,
      cells: [label, ...checkmarkCells],
    };
  });

const buildColumnLabels = (title: ReactNode) => [
  {
    id: 'title-r0',
    title,
    width: LABEL_WIDTH,
  },
  {
    id: 'Active-r0',
    title: 'Active',
    width: CHECKMARK_COLUMN_WIDTH,
  },
  {
    id: 'Strategic-r0',
    title: 'Strategic',
    width: CHECKMARK_COLUMN_WIDTH,
  },
  {
    id: 'Preferred-r0',
    title: 'Preferred',
    width: CHECKMARK_COLUMN_WIDTH,
  },
];

const launchTitle = (
  <FlexContainer direction="column">
    <h5>Launch</h5>
    <p>
      Gain exposure with a listing on the Sharesight Partner Directory and marketing launch
      activities
    </p>
  </FlexContainer>
);

const growTitle = (
  <FlexContainer direction="column">
    <h5>Grow</h5>
    <p>Unlock growth opportunities as you progress in status level</p>
  </FlexContainer>
);

const engageTitle = (
  <FlexContainer direction="column">
    <h5>Engage</h5>
    <p> Dive deeper with networking events that extend your expertise</p>
  </FlexContainer>
);

const innovateTitle = (
  <FlexContainer direction="column">
    <h5>Innovate</h5>
    <p>
      Immerse yourself in technology projects, and elevate your brand through custom audience
      amplification
    </p>
  </FlexContainer>
);

const launchLabels = [
  {
    key: 'launch-r1',
    label: (
      <>
        <p key="launch-r1-c1">
          <strong>Partner Directory</strong>
        </p>
        <Tooltip overlay="Your company logo and partnership overview on the Sharesight website" />
      </>
    ),
  },
  {
    key: 'launch-r2',
    label: (
      <>
        <p key="launch-r2-c1">
          <strong>Announcement</strong>
        </p>
        <Tooltip overlay="Partnership announcement on Sharesight blog and newsletter" />
      </>
    ),
  },
  {
    key: 'launch-r3',
    label: (
      <>
        <p key="launch-r3-c1">
          <strong>API demo environment </strong>
        </p>
        <Tooltip overlay="For trialling Sharesight integration" />
      </>
    ),
  },
  {
    key: 'launch-r4',
    label: (
      <>
        <p key="launch-r4-c1">
          <strong>Dedicated account management </strong>
        </p>
        <Tooltip overlay="Engage with your account manager to access the support of the Sharesight team" />
      </>
    ),
  },
  {
    key: 'launch-r5',
    label: (
      <>
        <p key="launch-r5-c1">
          <strong>Product training</strong>
        </p>
        <Tooltip overlay="Live Sharesight product training and onboarding for your team" />
      </>
    ),
  },
];

const growLabels = [
  {
    key: 'grow-r1',
    label: (
      <>
        <p key="grow-r1-c1">
          <strong>Marketing Hub</strong>
        </p>
        <Tooltip overlay="Access to branding materials and templates via the Sharesight Marketing Hub" />
      </>
    ),
  },
  {
    key: 'grow-r2',
    label: (
      <>
        <p key="grow-r2-c1">
          <strong>Marketing content</strong>
        </p>
        <Tooltip overlay="Gain exposure to Sharesight audience by contributing leadership content for blogs and articles" />
      </>
    ),
  },
  {
    key: 'grow-r3',
    label: (
      <>
        <p key="grow-r3-c1">
          <strong>Customer offers</strong>
        </p>
        <Tooltip overlay="Eligible for complementary customer offers" />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
  {
    key: 'grow-r4',
    label: (
      <>
        <p key="grow-r4-c1">
          <strong>Webinars &#38; events</strong>
        </p>
        <Tooltip overlay="Participate in educational and leadership-enhancing webinars and live events" />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
  {
    key: 'grow-r5',
    label: (
      <>
        <p key="grow-r5-c1">
          <strong>Leadership commentary</strong>
        </p>
        <Tooltip
          overlay="Opportunity to contribute commentary/quotes on industry topics to be shared in relevant
          Sharesight marketing and media content"
        />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
];

const engageLabels = [
  {
    key: 'engage-r1',
    label: (
      <>
        <p key="engage-r1-c1">
          <strong>Networking</strong>
        </p>
        <Tooltip
          overlay="Invitation to attend quarterly live networking events to engage with the Sharesight
          community"
        />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
  {
    key: 'engage-r2',
    label: (
      <>
        <p key="engage-r2-c1">
          <strong>API ecosystem group</strong>
        </p>
        <Tooltip overlay="Qualified to participate in this selective group to take a deep dive into our roadmap" />
      </>
    ),
    checkmarks: { preferred: true },
  },
  {
    key: 'engage-r3',
    label: (
      <>
        <p key="engage-r3-c1">
          <strong>Dedicated training days</strong>
        </p>
        <Tooltip
          overlay="A half day dedicated to training your staff across departments on GTM strategy,
      product bundles, and support"
        />
      </>
    ),
    checkmarks: { preferred: true },
  },
];

const innovateLabels = [
  {
    key: 'innovate-r1',
    label: (
      <>
        <p key="innovate-r1-c1">
          <strong>Latest API access</strong>
        </p>
        <Tooltip overlay="Priority access to the latest API functionality" />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
  {
    key: 'innovate-r2',
    label: (
      <>
        <p key="innovate-r2-c1">
          <strong>Quarterly business reviews</strong>
        </p>
        <Tooltip
          overlay="Review retrospective data to measure activities and plan growth strategy for the upcoming
        quarter"
        />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
  {
    key: 'innovate-r3',
    label: (
      <>
        <p key="innovate-r3-c1">
          <strong>Audience amplification co-op &#38; CSR</strong>
        </p>
        <Tooltip
          overlay="Take part in a gamified marketing initiative which includes content/media amplification, and
        CSR activity driven by the partner community (TBC)"
        />
      </>
    ),
    checkmarks: { strategic: true, preferred: true },
  },
];

export const LaunchTable = () => (
  <Table columns={buildColumnLabels(launchTitle)} rows={buildRows(launchLabels)} />
);

export const GrowTable = () => (
  <Table columns={buildColumnLabels(growTitle)} rows={buildRows(growLabels)} />
);

export const EngageTable = () => (
  <Table columns={buildColumnLabels(engageTitle)} rows={buildRows(engageLabels)} />
);

export const InnovateTable = () => (
  <Table columns={buildColumnLabels(innovateTitle)} rows={buildRows(innovateLabels)} />
);
